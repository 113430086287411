import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useAuth } from '@fivehealth/botero'
import Config from 'Config'
import { useConfig } from '../../context/ConfigContext'

const Login = () => {
  const history = useHistory()
  const { authState } = useAuth()
  const { search } = useLocation()
  const { organizationKey } = useParams()
  const { config } = useConfig()

  const getLoginProviderUid = () => {
    const orgKey = organizationKey.toLowerCase()
    if (
      orgKey === 'testcgh' ||
      (!Config.IS_PRODUCTION() && orgKey === 'testktph')
    ) {
      return config.EMAIL_OPT_LOGIN_PROVIDER_UID
    }
    const hccOrgs = ['cgh', 'ttsh', 'nccs', 'sgh']
    if (hccOrgs.includes(organizationKey.toLowerCase())) {
      return config.AZURE_LOGIN_PROVIDER_UID
    }
    return config.LOGIN_PROVIDER_UID
  }

  const loginProviderUid = getLoginProviderUid()

  const redirectUrl = `${window.location.origin}${Config.REDIRECT_PATH}`
  const loginUrl = `${config.LOGIN_URL}/?uid=${loginProviderUid}&redirectTo=${redirectUrl}&client_id=${config.AZURE_CLIENT_ID}&gql_uri=${config.GQL_ENDPOINT}`

  const applicationInput = JSON.stringify({
    organization_key: organizationKey.toLowerCase(),
    allow_guest: false,
  })

  const themeLogo = JSON.stringify({
    logo: Config.IS_PRODUCTION()
      ? `https://botmd-production-hippocrates-static.s3.amazonaws.com/hospital/${organizationKey.toUpperCase()}/full_size.png`
      : `https://botmd-staging-hippocrates-static.s3.amazonaws.com/hospital/${organizationKey.toUpperCase()}/full_size.png`,
  })

  useEffect(() => {
    // redirect authenticated user
    if (authState.authenticated) {
      const path = new URLSearchParams(search).get('path') || '/user_management'
      localStorage.setItem('loginEventTrackPending', true)
      return history.push(path)
    }

    // redirect to login
    if (organizationKey) {
      return window.location.replace(
        `${loginUrl}&applicationInput=${applicationInput}&theme=${themeLogo}&universalLogin=true`
      )
    }

    // not authenticated & missing organization key then redirect to home
    return history.push('/home')
  }, [authState, applicationInput])

  return <section>Redirecting...</section>
}

export default Login
